import { FORM_DATA_FILE_KEY } from "consts/forms";
import {
    useSimpleRequest,
    type UseSimpleRequestReturnCallbackArg,
    type UseSimpleRequestArg
} from "./useRequest";
import { fetchApi, FormSerializers } from "infrastructure/api";

export default function useFileUpload({
    requestSettings
}: {
    readonly requestSettings?: UseSimpleRequestArg;
} = {}) {
    const request = useSimpleRequest(requestSettings);

    return <T extends unknown>({
        url,
        files,
        requestSettings,
        paramName = FORM_DATA_FILE_KEY,
        ...restOptions
    }: {
        readonly url: string;
        readonly files: File[];
        readonly paramName?: string;
        readonly requestSettings?: UseSimpleRequestReturnCallbackArg<T>;
    } & Parameters<typeof fetchApi>[1]) => {
        const body = new FormData();

        for (const file of files) {
            body.append(paramName, file);
        }

        return request(() => fetchApi(url, {
            method: 'POST',
            ...restOptions,
            serializer: FormSerializers.FormData,
            body
        }), requestSettings);
    };
};
