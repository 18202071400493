import { useState } from "react";
import type { CustomFile } from "lib/upload";
import type { ResourceResponse } from "types";
import { useFileUpload } from "hooks";
import { ProcessResponseStrategy, useSimpleRequest } from "hooks/useRequest";
import { ApiRoutes } from "consts/enpoints/api";
import BusinessLogicException from "exceptions/BusinessLogicException";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";
import { downloadTemplateEmailsWhitelist, downloadTemplateEmailsWhitelistWithoutSdd } from "features/merchants/api";
import { getPromiseSettledResourceResult } from "util/resource";

export default function useImportWhitelist() {
    const [isProcessing, setProcessing] = useState(false);
    const [files, setFiles] = useState<Array<CustomFile>>([]);

    const requestTemplate = useSimpleRequest({
        setLoading: setProcessing,
        processResponseStrategy: ProcessResponseStrategy.Text
    });

    const { showNotication } = useActions();

    const uploadFiles = useFileUpload();

    const resetState = () => setFiles([]);

    const recordError = (fileOrIndex: CustomFile | number, response: ResourceResponse<unknown>) =>
        setFiles(files => files.map((file, index) => {
            if (Object.is(file, fileOrIndex) || Object.is(index, fileOrIndex)) {
                file.error = {
                    toString: () => response.message
                };
            }

            return file;
        }));

    const clearError = (file: CustomFile) =>
        setFiles(files => files.map(f => {
            if (Object.is(f.name, file.name)) {
                delete f.error;
            }

            return f;
        }));

    const onDrop = (files: Array<File>) =>
        setFiles(state => [
            ...state,
            ...files
        ]);

    const onRemove = (file: string | CustomFile) =>
        setFiles(files => files.filter(({ name }) => (
            !Object.is(
                name,
                typeof file === 'string'
                    ? file
                    : file.name
            )
        )));

    const getTemplateEmailsWhitelistWithoutSdd = () =>
        requestTemplate(downloadTemplateEmailsWhitelistWithoutSdd, {
            notifyOnSuccess: true,
            onSucessMessage: 'Template downloaded successfully'
        });

    const getTemplateEmailsWhitelist = () =>
        requestTemplate(downloadTemplateEmailsWhitelist, {
            notifyOnSuccess: true,
            onSucessMessage: 'Template downloaded successfully'
        });

    const upload = async () => {
        setProcessing(true);

        const responses = await Promise.allSettled(
            files.map(file => uploadFiles({
                url: ApiRoutes.PostEmailsWhitelist(),
                files: [file]
            }))
        );

        let hasError = false;

        responses.forEach((response, index) => {
            const result = getPromiseSettledResourceResult(response);

            if (result && !result.success) {
                recordError(index, result);

                hasError = true;
            }
        });

        if (hasError) {
            setProcessing(false);

            throw new BusinessLogicException('Failed to upload files', {});
        }

        showNotication({
            message: 'Whitelist uploaded successfully. It will be processed at the next scheduled time.',
            severity: NotificationSeverity.Success
        });

        setProcessing(false);

        resetState();
    };

    const retry = (file: CustomFile | string) => {
        if (typeof file === 'string') {
            return;
        }

        clearError(file);

        uploadFiles({
            url: ApiRoutes.PostEmailsWhitelist(),
            files: [file],
            requestSettings: {
                onError: response => recordError(file, response)
            }
        });
    };

    return {
        isProcessing,
        files,
        hasFiles: files.length > 0,
        onDrop,
        onRemove,
        upload,
        retry,
        getTemplateEmailsWhitelistWithoutSdd,
        getTemplateEmailsWhitelist
    };
};
