import { ErrorCode, type FileRejection } from "react-dropzone";
import { WithOverrides } from "lib/upload";

export default function useFileRejection(): WithOverrides {
    const withFileRejections = (fileRejections: Array<FileRejection>) =>
        fileRejections.map((fileRejection: FileRejection) => ({
            ...fileRejection,
            errors: fileRejection.errors.map(error => ({
                [ErrorCode.FileInvalidType]: {
                    ...error,
                    message: 'File type must be .csv, UTF-8 encoded, comma-separated'
                }
            }[error.code]) ?? error)
        }));

    return {
        withFileRejections
    };
};
