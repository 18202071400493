import { memo, type PropsWithChildren } from "react";
import blue from "@mui/material/colors/blue";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Upload } from 'lib/upload';
import useImportWhitelist from "./useImportWhitelist";
import useFileRejection from "./useFileRejection";

type Props = ReturnType<typeof useImportWhitelist>;

const WhitelistFileUploader = ({
    files,
    children,
    retry,
    onDrop,
    onRemove
}: PropsWithChildren<Props>) => (
    <Upload
        {...useFileRejection()}
        multiple
        files={files}
        retry={retry}
        onDrop={onDrop}
        onRemove={onRemove}
        accept={{
            'text/csv': ['.csv']
        }}
        placeholderProps={{
            titleSlot: (
                <Box>
                    <Typography gutterBottom variant="h6">
                        Drop or Select .CSV file
                    </Typography>
                    <Box
                        sx={{
                            '& *': {
                                fontSize: 14,
                                fontWeight: 600,
                                textDecoration: 'underline',
                                color: blue[700]
                            }
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            )
        }}
    />
);

export default memo(WhitelistFileUploader);
