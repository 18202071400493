import { memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Feedback from "ui/forms/Feedback";
import Profile from "ui/layouts/Profile";
import { UploadIllustration } from 'assets/illustrations';
import useHome from "./useHome";
import ImportWhitelist from "./ImportWhitelist";
import { Divider } from "@mui/material";

const Home = () => {
    const supportedFormatsMessage = (
        <Typography
            sx={{
                fontFamily: 'var(--primary-font)',
                fontSize: 12,
                color: 'grey.500',
                mb: 3
            }}
        >
            Supported formats: .csv, UTF-8 encoded, comma-separated
        </Typography>
    );

    return (
        <Profile>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 10,
                    alignItems: 'center'
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontFamily: 'var(--primary-font)',
                            fontSize: 18,
                            fontWeight: 700,
                            mb: 1
                        }}
                    >
                        Import your whitelist file to get started
                    </Typography>
                    {supportedFormatsMessage}
                    <ImportWhitelist>
                        {supportedFormatsMessage}
                    </ImportWhitelist>
                    <Divider sx={{ my: 2, mt: 3 }} />
                    <Typography
                        sx={{
                            fontFamily: 'var(--primary-font)',
                            mt: 3,
                        }}
                    >
                        Send us a feedback
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'var(--primary-font)',
                            fontSize: 18,
                            fontWeight: 700,
                            mb: 3
                        }}
                    >
                        Feel free to leave a suggestion for our product.
                    </Typography>
                    <Feedback
                        {...useHome()}
                    />
                </Box>
                <UploadIllustration />
            </Box>
        </Profile>
    );
};

export default memo(Home);
