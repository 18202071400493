import { memo, type PropsWithChildren } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import type { DialogCloseHandler } from "ui/atoms/Modal";
import { TriggerableDialog } from "ui/molecules/Dialog";
import WhitelistFileUploader from "./WhitelistFileUploader";
import useImportWhitelist from "./useImportWhitelist";

const ImportWhitelist = ({ children }: PropsWithChildren) => {
    const importWhitelist = useImportWhitelist();
    const {
        isProcessing,
        hasFiles,
        upload,
        getTemplateEmailsWhitelist,
        getTemplateEmailsWhitelistWithoutSdd,
    } = importWhitelist;

    const onClose: DialogCloseHandler = (_: unknown, reason?: string) => {
        if (reason !== 'backdropClick') {
            return;
        }

        throw new Error('https://www.youtube.com/watch?v=f4Mc-NYPHaQ');
    };

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    color='secondary'
                    variant='contained'
                    onClick={onClick}
                >
                    Import whitelist
                </LoadingButton>
            )}
            DialogActionsComponent={({ onClose }) => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 1
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1
                        }}
                    >
                        <Button
                            disabled={isProcessing}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            variant='contained'
                            disabled={!hasFiles}
                            loading={isProcessing}
                            onClick={() => upload()
                                .then(() => onClose())
                                .catch(() => null)}
                        >
                            Upload Files
                        </LoadingButton>
                    </Box>
                </Box>
            )}
            ModalProps={{
                onClose,
                titleSlot: 'Upload Whitelist(s) CSV',
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto',
                            '& .MuiDialogContent-root': {
                                border: 'none'
                            }
                        }
                    }
                }
            }}
        >
            <Box
                sx={{
                    mt: -1,
                    '& *': {
                        fontWeight: 600,
                        textDecoration: 'underline'
                    }
                }}
            >
                {children}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    marginBottom: 2,
                    paddingLeft: '1px'
                }}
            >
                <LoadingButton
                    variant='outlined'
                    color='info'
                    loading={importWhitelist.isProcessing}
                    onClick={getTemplateEmailsWhitelist}
                >
                    Download Template
                </LoadingButton>
                <LoadingButton
                    variant='outlined'
                    color='info'
                    loading={isProcessing}
                    onClick={getTemplateEmailsWhitelistWithoutSdd}
                >
                    Download Template without SDD
                </LoadingButton>
            </Box>
            <WhitelistFileUploader
                {...importWhitelist}
            >
                {children}
            </WhitelistFileUploader>
        </TriggerableDialog>
    );
};

export default memo(ImportWhitelist);
