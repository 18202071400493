import { ApiRoutes } from "consts/enpoints/api";
import type { Merchant, MerchantKYCService, MerchantSettings } from "features/merchants/types";
import { fetchApi } from "infrastructure/api";
import { createOrUpdateResource, downloadFileApi } from "infrastructure/api/api";

export const getMerchants = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchants}${queryString}`);

export const createOrUpdateMerchant = (body: Partial<Merchant>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PostMerchant,
        getId: body => body.coreId
    });

export const getMerchantKYCSettings = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantKYCSettings}${queryString}`);

export const updateOrCreateMerchantKYCSettings = (body: Partial<MerchantKYCService> & Required<Pick<
    MerchantKYCService,
    | 'merchantId'
    | 'kycTypeId'
    | 'summedAmountEurLimit'
>>) =>
    fetchApi(ApiRoutes.PutMerchantKYCSettings(
        body.merchantId,
        body.kycTypeId
    ), {
        method: 'PUT',
        body
    });

export const getMerchantSettings = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantSettings}${queryString}`);

export const updateOrCreateMerchantSettings = ({ coreId, ...body }:
    & Pick<MerchantSettings, 'coreId'>
    & Partial<MerchantSettings>
) =>
    fetchApi(ApiRoutes.PutMerchantSettings(coreId), {
        method: 'PUT',
        body
    });

export const downloadTemplateEmailsWhitelistWithoutSdd = () =>
    downloadFileApi(ApiRoutes.GetTemplateEmailsWhitelistWithoutSdd(), {
        headers: {
            Accept: 'text/csv',
        },
        fileName: 'emails_whitelist_template_without_sdd.csv'
    });

export const downloadTemplateEmailsWhitelist = () =>
    downloadFileApi(ApiRoutes.GetTemplateEmailsWhitelist(), {
        headers: {
            Accept: 'text/csv',
        },
        fileName: 'emails_whitelist_template.csv'
    });
